const faqData= [
    {
        id:"faq1",
        toggler:"#faq1",
        title:"Kuzey Kıbrıs'ta araç kiralamak için yaş sınırınız var mı?",
        description:
        "Evet. Kıbrıs'ta araç kiralayabilmeniz için en az 24 yaşında olmanız gerekmektedir."
    },

    {
        id:"faq2",
        toggler:"#faq2",
        title:"Minimum kaç gün kiralama yapıyorsunuz? 1 veya 2 gün kiralama yapabilir miyim?",
        description:
        "Kuzey Kıbrıs'ta minimum kiralama süresi 3 gündür. 1-2 gün kiralamalarınızda 3 günlük fiyat üzerinden işlem yapılır."
    },
    {
        id:"faq3",
        toggler:"#faq3",
        title:"Kuzey Kıbrıs'ta ikamet ediyorum araba kiralayabilir miyim?",
        description:
        "Kuzey Kıbrıs'ta Ercan Havalimanı ve Girne'den araba kiralayabilir misiniz. Kuzey Kıbrıs'ta oturma izni veya çalışma izniniz varsa Kuzey Kıbrıs Türk Cumhuriyeti ehliyetiniz olmalıdır."
    },

    {
        id:"faq4",
        toggler:"#faq4",
        title:"Kuzey Kıbrıs'ta öğrenciye araba kiralıyor musunuz?",
        description:
        "En az 24 yaşındaysanız ve Kuzey Kıbrıs Türk Cumhuriyeti ehliyetine sahipseniz araba kiralayabilirsiniz."
    },
    {
        id:"faq5",
        toggler:"#faq5",
        title:"Kuzey Kıbrıs Ercan Havalimanı'nda araba kiralıyor musunuz?",
        description:
        "Evet. Kuzey Kıbrıs Ercan Havalimanı'nda otopark yerimiz vardır. Havalimanından araç alıp tekrar havalimanına bırakabilirsiniz."
    },

    {
        id:"faq6",
        toggler:"#faq6",
        title:"Ödemeyi ne zaman yapıyoruz? Kredi kartı ile ödeme yapabilir miyim? ",
        description:
        "Ödemeyi arabayı size teslim ettiğimizde yapıyorsunuz. Kredikartı ile ödeme yapabilirsiniz."
    },
    {
        id:"faq7",
        toggler:"#faq7",
        title:"Rezervasyon yapmamız gerekiyor mu? Geldiğimde kiralamak için araba bulabilir miyim? ",
        description:
        "Rezervasyon yapmanız sizler için en sağlıklısı olacaktır rezervasyonları whatsapp üzerinden sonuçlandırıyoruz. Geldiğinizde müsait araba bulamayabilirsiniz."
    },

    {
        id:"faq8",
        toggler:"#faq8",
        title:"Dizel aracınız var mı? ",
        description:
        "Sadece üst segment araçlarımız dizeldir. "
    },
    {
        id:"faq9",
        toggler:"#faq9",
        title:"TC ehliyetim ile araba kiralayabilir miyim? ",
        description:
        "Turistseniz TC ehliyetiniz ile Kuzey Kıbrıs Türk Cumhuriyeti'nde araba kiralayabilirsiniz. "
    },
    {
        id:"faq10",
        toggler:"#faq10",
        title:"Arabayı kiralayandan başkası kullanabilir mi? ",
        description:
        "Malesef. Sadece kira sözleşmesinde ismi yazan müşterilerimiz arabayı kullanabilir. Aksi halde aracı sigorta kapsamaz ve tüm sorumluluk kira sözleşmesinde ismi yazan müşterilerde olur. "
    },
    {
        id:"faq11",
        toggler:"#faq11",
        title:"Şoförlü araç kiralıyor musunuz? ",
        description:
        "Malesef böyle bir hizmetimiz bulunmamaktadır. "
    },
    {
        id:"faq12",
        toggler:"#faq12",
        title:"İndirim uyguluyor musunuz? ",
        description:
        "Sadece uzun vadeli araç kiralamalarınızda indirim uygulayabiliriz. Detaylı bilgi için bizimle iletişim kurabilirsiniz."
    },
    {
        id:"faq13",
        toggler:"#faq13",
        title:"Rezervasyon için istenen bilgiler nedir? ",
        description:
        "Whatsapp üzerinden isim soyisim, uçuş detayları ve iletişim numarası vermeniz yeterli olacaktır. Gerekli görülmesi halinde rezervasyon tarihinize göre sizden sadece kurumsal banka hesabımıza 1 günlük kapora alıyoruz. "
    },
    {
        id:"faq14",
        toggler:"#faq14",
        title:"Kredikartına provizyon uyguluyor musunuz? ",
        description:
        "Sadece araç kiralama ücreti alınır. Kredikartınıza herhangi bir bloke uygulanmaz. "
    },
    {
        id:"faq15",
        toggler:"#faq15",
        title:"Çocuk kolduğu veriyor musunuz? ",
        description:
        "Talep etmeniz halinde çocuk koltuklarımız müsaitse ücretsiz olarak veriyoruz. "
    },
    {
        id:"faq16",
        toggler:"#faq16",
        title:"Teslim tarihinden önce arabayı teslim edersem geri ödeme yapıyor musunuz? ",
        description:
        "Aracı müşterilerimize verdikten sonra sözleşme sisteme hemen giriliyor bu yüzden erken teslim ettiğinizde herhangi bir geri ödeme yapmıyoruz. Yakıt iadesi de yapılmaz.  "
    },
    {
        id:"faq17",
        toggler:"#faq17",
        title:"Araç deponuzu full mu veriyorsunuz? ",
        description:
        "Araçlarımız full depo olmuyor. Genel olarak çeyrek depo verip aynı şekilde teslim alıyoruz. "
    },
    {
        id:"faq18",
        toggler:"#faq18",
        title:"Soldan direksiyonlu ve manuel vites aracınız var mı?",
        description:
        "Hayır. Araçlarımızın hepsi otomatik vites ve sağdan direksiyonludur.  "
    },

]
export default faqData;