import React, { useEffect } from "react";

import carData from "../assets/data/carData";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import { useParams } from "react-router-dom";
import BookingForm from "../components/UI/BookingForm";

const CarDetails = () => {
  const { slug } = useParams();

  const singleCarItem = carData.find((item) => item.carName === slug);

  useEffect(() => {
    window.scrollTo(1, 200);
  }, [singleCarItem]);



  return (
    <>
      <Helmet title={singleCarItem.carName}>
        <section>
          <Container>
            <Row>
              <Col lg="6">
                <img src={singleCarItem.imgUrl} alt="" className="w-100" />
              </Col>

              <Col lg="6">
                <div className="car__info">
                  <h2 className="section__title">{singleCarItem.carName}</h2>

                  <div className=" d-flex align-items-center gap-5 mb-4 mt-3">
                     <h6 className="rent__price fw-bold fs-4">
                      ₺{singleCarItem.price} / Günlük
                    </h6> 

                  </div>

                  <p className="section__description">
                    {singleCarItem.description}
                  </p>

                  <Row>
                    <div className=" d-flex align-items-center mt-3" >
                      <Col>
                        <span className=" d-flex align-items-center gap-1 section__description">
                          <i
                            className="ri-building-2-line"
                            style={{ color: "#c39341e5" }}
                          ></i>{" "}
                          {singleCarItem.brand}
                        </span>
                      </Col>
                      <Col>
                        <span className=" d-flex align-items-center gap-1 section__description">
                          <i
                            className="ri-roadster-line"
                            style={{ color: "#c39341e5" }}
                          ></i>{" "}
                          {singleCarItem.model}
                        </span>
                      </Col>
                      <Col>
                        <span className=" d-flex align-items-center gap-1 section__description">
                          <i
                            className="ri-settings-2-line"
                            style={{ color: "#c39341e5" }}
                          ></i>{" "}
                          {singleCarItem.automatic}
                        </span>
                      </Col>
                    </div>
                  </Row>

                  <Row>
                    <div className=" d-flex align-items-center mt-3" >
                      <Col>
                        <span className=" d-flex align-items-center gap-1 section__description">
                          <i className="ri-oil-fill" style={{ color: "#c39341e5" }}></i>{" "}
                          {singleCarItem.fuel}
                        </span>
                      </Col>
                      <Col>
                        <span className=" d-flex align-items-center gap-1 section__description">
                          <i
                            className="ri-bluetooth-line"
                            style={{ color: "#c39341e5" }}
                          ></i>{" "}
                          {singleCarItem.bluetooth}
                        </span>
                      </Col>
                      <Col>
                        <span className=" d-flex align-items-center gap-1 section__description">
                          <i
                            className="ri-group-fill"
                            style={{ color: "#c39341e5" }}
                          ></i>{" "}
                          {singleCarItem.person}
                        </span>
                      </Col>
                    </div>
                  </Row>

                </div>
              </Col>

              <Col lg="7" className="mt-5">
                <div className="booking-info mt-5">
                  <h5 className="mb-4 fw-bold ">Rezervasyon </h5>
                  <BookingForm
                    carName={singleCarItem.carName}
                    price={singleCarItem.price}
                  />
                </div>
              </Col>

            </Row>
          </Container>
        </section>
      </Helmet>
    </>

  );
};

export default CarDetails;
