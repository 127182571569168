import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Container, Row, FormGroup, Label, Input, Col } from "reactstrap";
import carData from "../../assets/data/carData";
import CarItemForBooking from "../../components/UI/CarItemForBooking";
import "../../styles/select-car-modal.css";
import BookingInformation from "./BookingInformation";
import "../../styles/car-item-booking.css";
import moment from 'moment';

const SelectCarModal = (props) => {
  const [rSelected, setRSelected] = useState(null);
  let start = new Date(props.startTime)
  let end = moment(props.endTime);
  const diff = end.diff(start);
  const diffDuration = moment.duration(diff);
  const days = diffDuration.days();
  const hours = diffDuration.hours();

  return (

    <Modal
      backdrop={"static"}
      isOpen={props.modal}
      toggle={props.toggle}
      className="select__car-modal" >
      <ModalHeader toggle={props.toggle}>Araç Seçimi</ModalHeader>
      <ModalBody>
        <Container>
          <FormGroup >
            <Row>
              <Col lg={3} md={3} xs={3}>
                <Label >  Alış:  </Label>
              </Col>
              <Col lg={5} md={5} xs={9}>
                <Input
                  id="pickUp"
                  name="pickUp"
                  disabled
                  value={props.pickUpPlace + " - " + props.startDate}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={3} md={3} xs={3}>
                <Label >  Teslim:  </Label>
              </Col>
              <Col lg={5} md={5} xs={9}>
                <Input
                  id="dropOffPlace"
                  name="dropOffPlace"
                  disabled
                  value={props.dropOffPlace + " - " + props.endDate}
                />
              </Col>
            </Row>



          </FormGroup>

          <FormGroup >
            <Row>
              <Col lg={3} md={3} xs={4}>
                <Label >  Toplam Gün:   </Label>
              </Col>
              <Col lg={5} md={5} xs={8}>
                <Input
                  id="totalDays"
                  name="totalDays"
                  disabled
                  value={
                    // hours > 1 ?
                    //   days + " gün ve " + hours + " saat => " + (days + 1) + " gün" :
                    //   days + " gün ve " + hours + " saat => " + (days) + " gün"
                    hours > 2 ?
                      (days + 1) + " gün" :
                      (days) + " gün"
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col  lg={2} md={2} xs={2}>
                <Label className="stars" >  * * *   </Label>
              </Col>
              <Col lg={10} md={10} xs={10}>
              <Label className="bilgilendirme" >   Minimum 3 gün üzerinden işlem yapıldığından dolayı; 1 veya 2 günlük kiralamalarınızda 
              3 günlük fiyatlandırma yapılır.   </Label>
              </Col>
            </Row>
            <Row>
              <Col  lg={2} md={2} xs={2}>
                <Label className="stars" >  * * *   </Label>
              </Col>
              <Col lg={10} md={10} xs={10}>
              <Label className="bilgilendirme" >   Aylık kiralamalarınızda özel fiyat için lütfen bizimle iletişime geçiniz. </Label>
              </Col>
            </Row>
            
          </FormGroup>

          <Row>

            {carData.slice(0, 11).map((item) => (
              <CarItemForBooking
                item={item}
                key={item.id}
                toggleNested={props.toggleNested}
                setRSelected={setRSelected}
                rSelected={rSelected}
                active={rSelected === item.id}
                days={hours > 2 ? (days + 1) : (days)}
              />
            ))}
          </Row>
        </Container>


        <BookingInformation
          closeAll={props.closeAll}
          toggleNested={props.toggleNested}
          toggleAll={props.toggleAll}
          nestedModal={props.nestedModal}
          toggle={props.toggle}
          pickUp={props.pickUpPlace + " " + props.startDate}
          dropOff={props.dropOffPlace + " " + props.endDate}
          rSelected={rSelected}
          totalDays={hours > 2 ? days + 1 : days}
        />


      </ModalBody>
      <ModalFooter>
        <Button className="select__car-cansel" onClick={props.toggle}>
          İptal
        </Button>
      </ModalFooter>
    </Modal>


  )
}
<script src="https://momentjs.com/downloads/moment.js"></script>
export default SelectCarModal