import React from "react";

import Slider from "react-slick";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";

import "../../styles/hero-slider.css";

const HeroSlider = () => {
  const settings = {
    fade: true,
    speed: 8000,
    autoplaySpeed: 8000,
    infinite: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  };
  return (
    <Slider {...settings} className="hero__slider">
      <div className="slider__item slider__item-01 mt0">
        <Container>
          <div className="slider__content ">
            <h1 className="text-light mb-3">Kıbrıs'ta Araç Kiralama-1200 TL'den baslayan fiyatlar</h1>
            <p className="text-light mb-4">Kuzey Kıbrıs Türk Cumhuriyeti'nde; Ercan Havalimanı 
            ve Girne'de uygun fiyatlı araç kiralama hizmeti vermekteyiz. Lüks ve orta segment araç seçeneklerimiz mevcuttur.
            Uzun vadeli kiralamalarınız için bizden fiyat alabilirsiniz.</p>

            <button className="btn reserve__btn mt-1">
              <Link to="/Araclarimiz">Araçlarımız</Link>
            </button>
          </div>
        </Container>
      </div>

      <div className="slider__item slider__item-02 mt0">
        <Container>
          <div className="slider__content ">
            <h1 className="text-light mb-3">Lüks ve Orta Segment Araçlar</h1>
            <p className="text-light mb-4">Kuzey Kıbrıs Türk Cumhuriyeti'nde; Ercan Havalimanı 
            ve Girne'de uygun fiyatlı araç kiralama hizmeti vermekteyiz. Lüks ve orta segment araç seçeneklerimiz mevcuttur.
            Uzun vadeli kiralamalarınız için bizden fiyat alabilirsiniz.</p>

            <button className="btn reserve__btn mt-1">
              <Link to="/Araclarimiz">Araçlarımız</Link>
            </button>
          </div>
        </Container>
      </div>

      <div className="slider__item slider__item-03 mt0">
        <Container>
          <div className="slider__content ">
            <h1 className="text-light mb-3">Uzun Vade Kiralamalarda İndirim</h1>
            <p className="text-light mb-4">Kuzey Kıbrıs Türk Cumhuriyeti'nde; Ercan Havalimanı 
            ve Girne'de uygun fiyatlı araç kiralama hizmeti vermekteyiz. Lüks ve orta segment araç seçeneklerimiz mevcuttur.
            Uzun vadeli kiralamalarınız için bizden fiyat alabilirsiniz.</p>

            <button className="btn reserve__btn mt-1">
              <Link to="/Araclarimiz">Araçlarımız</Link>
            </button>
          </div>
        </Container>
      </div>
    </Slider>
  );
};

export default HeroSlider;
