import React, { useEffect } from "react";
import Helmet from "../components/Helmet/Helmet";
import { Button, Card, CardBody, UncontrolledCollapse, Container, Col, Row } from "reactstrap";
import CommonSection from "../components/UI/CommonSection";
import faqData from "../assets/data/faqData";
import driveImg from "../assets/all-images/drive.jpg";
import "../styles/faq.css";
const Faq = () => {
    useEffect(() => {
        window.scrollTo(1, 1);
    });
    return (
        <Helmet title="Kuzey Kıbrıs'ta Kiralama Şartları ve S.S.S">
            <CommonSection title="Kıbrıs'ta Kiralama Şartları ve S.S.S" />
            <Container>
                <h2>Kuzey Kıbrıs'ta Kiralama Şartları ve Sıkça Sorulan Sorular</h2>
                <Row>
                    <Col lg="8" >
                        <section>
                            {faqData.map((item) => (
                                <div>
                                    <Button
                                        color="primary"
                                        id={item.id}
                                        className="faq-title"
                                    >
                                        {item.title}
                                    </Button>
                                    <UncontrolledCollapse toggler={item.toggler} className="collapse-faq" >
                                        <Card >
                                            <CardBody>
                                                {item.description}
                                            </CardBody>
                                        </Card>
                                    </UncontrolledCollapse>
                                </div>
                            ))}

                        </section>
                    </Col>

                    <Col lg="4" >
                        <section>
                            <div className="contact__info-faq">
                                <h6 className="contact__info-faq-title">Yardım ve Rezervasyon İçin</h6>
                               
                                <div className="faq-img"> 
                                    <img src={driveImg} alt="" className="w-100 rounded-3" />
                               </div> 
                              
                                <div className=" d-flex align-items-center gap-2">
                                    <i className="ri-whatsapp-fill color-wp" />
                                    <a href="https://wa.me/905488382222?text=Merhaba%20araç%20kiralamak%20istiyorum" rel="nofollow">+90-548-838-2222</a>
                                </div>
                                <div className=" d-flex align-items-center gap-2">
                                    <i className="ri-phone-fill" />
                                    <a href="tel://+905488382222" rel="nofollow">+90-548-838-2222</a>
                                </div>

                                <div className=" d-flex align-items-center gap-2">
                                    <i className="ri-mail-send-fill color-mail" />
                                    <a href="mailto:newmonthrentacar@gmail.com" >newmonthrentacar@gmail.com</a>
                                </div>         

                            </div>
                        </section>
                    </Col>

                </Row>


            </Container>

        </Helmet>
    )
}

export default Faq