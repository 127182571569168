import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
// import { Link } from "react-router-dom";
import "../../styles/car-item-booking.css";

const CarItemForBooking = (props) => {
  const { imgUrl, model, carName, automatic, price, fuel, bluetooth } = props.item;
  const [days, setDays] = useState(null)
  const [carInfo, setCarInfo] = useState(true)

  useEffect(() => {
    (props.days< 3) ? setDays(3) : setDays(props.days);
  });
  function handleChangeCarSelect(carName, model) {

    setCarInfo(!carInfo)
    if (carInfo) {
      
       props.setRSelected(carName + "- Toplam : ₺ " + (price * days))
      // props.setRSelected(carName + " - " + (model) )
      props.toggleNested(true)
    }
    else {
      props.setRSelected("")
      props.toggleNested(false)
    }

  }
  return (
    <>
      <Col lg="3" md="4" sm="4" xs="6" className="mb-1">
        <div className="modal__car__item" onClick={() => handleChangeCarSelect(carName, model)}
        >
          <div className="modal__car__img">
            <img src={imgUrl} alt="" className=" " />
          </div>

          <div className="modal__car__item-content mt-2">
            <h4 className="section__title text-center">{carName} </h4>
             <h6 className="rent__price text-center ">
              Toplam: ₺{price * days} <span>/ {props.days} Gün</span>
            </h6> 

            <Row>
              <div className="modal__car__item-info d-flex align-items-center  mt-1 mb-1">
                <Col>
                  <span className=" d-flex align-items-center gap-1">
                    <i className="ri-car-line"></i> {model}
                  </span>
                </Col>
                <Col>
                  <span className=" d-flex align-items-center gap-1 ">
                    <i className="ri-oil-fill"></i> {fuel}
                  </span>

                </Col>
              </div>
            </Row>
            <Row>
              <div className="modal__car__item-info d-flex align-items-center justify-content-between mt-2 mb-2">
                <Col>
                  <span className=" d-flex align-items-center gap-1">
                    <i className="ri-bluetooth-line"></i> {bluetooth}
                  </span>
                </Col>
                <Col>
                  <span className=" d-flex align-items-center gap-1">
                    <i className="ri-settings-2-line"></i> {automatic}
                  </span>
                </Col>
                <Col>
                </Col>
              </div>
            </Row>

            {/* <button className=" w-50 modal__car__item-btn modal__car__btn-rent" 
            onClick={() =>denemeF(carName,price) }  
          >
            Select
          </button> */}

          </div>
        </div>


      </Col>

    </>

  );

};

export default CarItemForBooking;
