import React from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/car-item.css";

const CarItem = (props) => {
  const { imgUrl, model, carName, automatic, bluetooth, price, fuel, person } = props.item;

  return (
    <Col lg="4" md="4" sm="6" className="mb-5">
      <div className="car__item">
        <div className="car__img">
          <img src={imgUrl} alt="" className="w-100" />
        </div>
       
        <div className="car__item-content mt-4">
        <h4 className="section__title text-center">{carName}</h4>
          <h6 className="rent__price text-center mt-">
            ₺{price} <span>/ Günlük</span>
          </h6>  

          <Row>
            <div className="car__item-info d-flex align-items-center  mt-1 mb-1">
              <Col>
                <span className=" d-flex align-items-center gap-1">
                  <i className="ri-car-line"></i> {model}
                </span>
              </Col>
              <Col>
                <span className=" d-flex align-items-center gap-1 ">
                  <i className="ri-group-fill"></i> {person}
                </span>

              </Col>
              <Col>
                <span className=" d-flex align-items-center gap-1 ">
                  <i className="ri-oil-fill"></i> {fuel}
                </span>

              </Col>
            </div>
          </Row>
          <Row>
            <div className="car__item-info d-flex align-items-center justify-content-between mt-3 mb-4">
              <Col>
                <span className=" d-flex align-items-center gap-1">
                  <i className="ri-bluetooth-line"></i> {bluetooth}
                </span>
              </Col>
              <Col>
                <span className=" d-flex align-items-center gap-1">
                  <i className="ri-settings-2-line"></i> {automatic}
                </span>
              </Col>
              <Col>
              </Col>
            </div>
          </Row>

          <button className=" w-100 car__item-btn car__btn-rent">
            <Link to={`/Araclarimiz/${carName}`}>Rezervasyon</Link>
          </button>

        </div>
      </div>
    </Col>
  );
};

export default CarItem;
