import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label } from 'reactstrap';
import "../../styles/booking-information.css";
import ReservationCompleted from './ReservationCompleted';

const BookingInformation = (props) => {

  const [nameSurname, setNameSurname] = useState(null)
  const [nameSurnameValid, setNameSurnameValid] = useState(false)
  const [telNumber, setTelNumber] = useState(null)
  const [telNumberValid, setTelNumberValid] = useState(false)
  const [email, setEmail] = useState(null)
  // const [emailValid, setEmailValid] = useState(false)
  const [person, setPerson] = useState(null)
  const [personValid, setPersonValid] = useState(false)
  const [message, setMessage] = useState(null)
  const [checked, setChecked] = useState(false);

  const [completedModal, setcompletedModal] = useState(false);
  const completedToggle = () => setcompletedModal(!completedModal);

  function handleChange(event) {
    const { name, value } = event.target;
    validate(name, value);
  }

  function validate(name, value) {
    if (name === "q6_isim" && value === "") {
      setNameSurnameValid(false)
      setNameSurname(null)
    }
    if (name === "q6_isim" && value !== "") {
      setNameSurnameValid(true)
      setNameSurname(value)
    }
    if (name === "q7_email" && value === "") {
      // setEmailValid(false)
      setEmail(null)
    }
    if (name === "q7_email" && value !== "") {
      // setEmailValid(true)
      setEmail(value)
    }
    if (name === "q8_tel" && value === "") {
      setTelNumberValid(false)
      setTelNumber(null)
    }
    if (name === "q8_tel" && value !== "") {
      setTelNumberValid(true)
      setTelNumber(value)
    }
    if (name === "q9_kisisayisi" && value === "") {
      setPersonValid(false)
      setPerson(null)
    }
    if (name === "q9_kisisayisi" && value !== "") {
      setPersonValid(true)
      setPerson(value)
    }
    if (name === "q10_mesaj" && value !== "") {
      setMessage(value)
    }
  }


  return (
    <>
      <Modal
        isOpen={props.nestedModal}
        toggle={props.toggleNested}
        onClosed={props.closeAll ? props.toggle : undefined}
        backdrop={"static"}
        className="booking__information-modal"
      >
        <ModalHeader>Rezervasyon Talebi Gönder</ModalHeader>
        <ModalBody>

          <Form action="https://submit.jotform.com/submit/231604689612055" method="post" name="form_231604689612055" id="231604689612055" autoComplete='off'>
            <FormGroup className="booking__information-form d-inline-block me-4 mb-4">
              <input type="text" name="q3_alis" value={"Alış: " + props.pickUp} readOnly />
            </FormGroup>
            <FormGroup className="booking__information-form d-inline-block me-4 mb-4">
              <input type="text" name="q4_teslim" value={"Teslim: " + props.dropOff} readOnly />
            </FormGroup>
            <FormGroup className="booking__information-form d-inline-block me-4 mb-4">
              <input type="text" name="q5_aracbilgisi" value={props.totalDays + " Gün / / " + props.rSelected || ""} readOnly />

            </FormGroup>
            <FormGroup className={
              nameSurnameValid ?
                "booking__information-form d-inline-block me-4 mb-4" :
                "booking__information-formValid d-inline-block me-4 mb-4"}  >
              <input
                type="text"
                placeholder="Ad Soyad"
                name="q6_isim"
                onChange={handleChange}
                value={nameSurname || ""}
              />
            </FormGroup>
            <FormGroup className="booking__information-form d-inline-block me-4 mb-4"   >
              <input
                type="email"
                placeholder="Email"
                name="q7_email"
                onChange={handleChange}
                value={email || ""}
              />

            </FormGroup>
            <FormGroup className={
              telNumberValid ?
                "booking__information-form d-inline-block me-4 mb-4" :
                "booking__information-formValid d-inline-block me-4 mb-4"}  >
              <input
                type="number"
                placeholder="Telefon"
                name="q8_tel"
                onChange={handleChange}
                value={telNumber || ""}
              />
            </FormGroup>

            <FormGroup className={
              personValid ?
                "select__group booking__information-form d-inline-block me-4 mb-4" :
                "select__group booking__information-formValid d-inline-block me-4 mb-4"
            }

            >
              <select name="q9_kisisayisi" id="" onChange={handleChange} value={person || ""}>
                <option value="" hidden >Kişi Sayısı</option>
                <option value="1 Kişi">1 Kişi</option>
                <option value="2 Kişi">2 Kişi</option>
                <option value="3 Kişi">3 Kişi</option>
                <option value="4 Kişi">4 Kişi</option>
                <option value="5+ Kişi">5+ Kişi</option>
              </select>

            </FormGroup>
            <FormGroup>
              <textarea
                rows={4}
                type="textarea"
                className="textarea"
                placeholder="Mesajınız"
                name="q10_mesaj"
                onChange={handleChange}
                value={message || ""}
              ></textarea>

            </FormGroup>
            {/* <FormGroup className='mt-2 ' onClick={() => setChecked((state) => !state)}>
              <input type="checkbox"
                //  defaultChecked={checked}
                checked={checked}
              //  onChange={() => setChecked((state) => !state)} 
              />
              {' '}
              <Label check>
                En az 24 yaşındaysanız kutucuğu işaretleyiniz.
              </Label>
            </FormGroup> */}
            <Button
              className="booking__information-reservation"
              onClick={completedToggle}
              type="submit"
              disabled={
                (nameSurnameValid && telNumberValid && personValid ) ?
                  false : true

              }
              // disabled={
              //   (nameSurnameValid && telNumberValid && personValid && checked) ?
              //     false : true

              // }
            >
              Gönder
            </Button>
          </Form>

        </ModalBody>
        <ModalFooter>
          <Button className="booking__information-cansel"
            onClick={props.toggleNested}
          >
            İptal
          </Button>
        </ModalFooter>
      </Modal>

      <ReservationCompleted
        toggleNested={props.toggleNested}
        completedModal={completedModal}
        completedToggle={completedToggle}
        toggleAll={props.toggleAll}
      />
    </>
  )
}

export default BookingInformation