const serviceData = [
  {
    id: 1,
    title: "Bir Telefon Kadar Yakınınızdayız",
    icon: "ri-customer-service-2-line",
    desc: "Rezervasyon taleplerini çağrı yoluyla oluşturabilir, araç kiralamalarından sonra olası bir sorunda 7/24 ulaşabilirsiniz.",
  },

  {
    id: 2,
    title: "Klimalı-Otomatik Vites Araçlar",
    icon: "ri-asterisk",
    desc: "Araçlarımızın hepsi klimalı ve Kuzey Kıbrıs'ta rahat sürüş için otomatik vitestir. Araçlarımızın servisleri yapılmaktadır.",
  },

  {
    id: 3,
    title: "Kilometre Sınırı Yok",
    icon: "ri-roadster-line",
    desc: "Kuzey Kıbrıs'ta dilediğiniz yerlere özgürce gidebilmeniz için araçlarımızda kilometre sınırı yoktur. ",
  },

  {
    id: 4,
    title: "Hızlı ve Kolay Rezervasyon",
    icon: "ri-timer-flash-line",
    desc: "Formu doldurarak rezervasyon talebinizi ilettikten hemen sonra dönüş sağlıyoruz. Whatsapp üzerinden de talepte bulunabilirsiniz.",
  },

  {
    id: 5,
    title: "Girne Teslim",
    icon: "ri-map-pin-line",
    desc: "Kuzey Kıbrıs'ta iki farklı lokasyonumuz vardır. Girne lokasyonumuzdan da araç kiralayabilirsiniz.",
  },

  {
    id: 6,
    title: "Havalimanı Teslim",
    icon: "ri-flight-takeoff-line",
    desc: "Kuzey Kıbrıs Ercan Havalimanı'ndan da araç kiralama hizmeti sunmaktayız. ",
  },
];

export default serviceData;
