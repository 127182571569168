import React from "react";

import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/footer.css";

const quickLinks = [
  {
    path: "/",
    display: "Anasayfa",
  },
  {
    path: "/Hakkimizda",
    display: "Hakkımızda",
  },
  {
    path: "/Araclarimiz",
    display: "Araçlarımız",
  },
  {
    path: "/SSS",
    display: "Şartlar ve S.S.S.",
  },
  // {
  //   path: "/blogs",
  //   display: "Blog",
  // },
  {
    path: "/Iletisim",
    display: "İletişim",
  },
];

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="4" md="4" sm="12">
            <div className="logo footer__logo">
              <h1>
                <Link to="/" className=" d-flex align-items-center gap-2">
                  <i className="ri-car-fill"></i>
                  <span>
                  New Month <br /> Rent A Car
                  </span>
                </Link>
              </h1>
            </div>
            <p className="footer__logo-content">
            2010 yılından beri süregelen tecrübesiyle siz değerli müşterilerimize 
              uygun fiyatlarla Kıbrıs'ta araç kiralama hizmeti sunan firmamız sizi
               memnun etmenin gururunu yaşamaktadır. New Month Rent a Car firması olarak 
               Kuzey Kıbrıs - Girne ve Ercan Havalimanı'nda sizlere araç kiralama hizmeti sunmaktayız.
            </p>
          </Col>

          <Col lg="4" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">Hızlı Linkler</h5>
              <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>

          <Col lg="4" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title mb-4">Merkez Ofisler</h5>
              <p className="office__info">Ercan Havalimanı - Kuzey Kıbrıs</p>
              <p className="office__info">Semih Sancar Cad. Girne - Kuzey Kıbrıs</p>
              <p className="office__info">Tel: +90 548 838 2222</p>

              <p className="office__info">Email: newmonthrentacar@gmail.com</p>

              {/* <p className="office__info">Çalışma Zamanı: 09:00 - 20:00</p> */}
            </div>
          </Col>
{/* 
          <Col lg="6" md="6" sm="12">
            <div className="google-map-code mb-4">
            <h5 className="footer__link-title">Girne Ofis</h5>
            <iframe 
          src="
          https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13020.177199637425!2d33.3282868!3d35.
          3297214!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14de6df47079a441%3A0x3c1066106f4669ce!2sNew%20
          Month%20Rent%20a%20Car!5e0!3m2!1str!2s!4v1686254255827!5m2!1str!2s" 
          width="100%" 
          height="300" 
          style={{border:0}} 
          allowFullScreen="" 
          title="Location"
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade"> 
            
          </iframe>
            </div>
          </Col>

          <Col lg="6" md="6" sm="12">
            <div className="google-map-code mb-4">
            <h5 className="footer__link-title">Ercan Havalimanı Ofis</h5>
            <iframe 
          src="
          https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2588.998843161453!2d33.50304279859238!
          3d35.15826769762087!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14de3cf5804b085b%3A0x484a9aac114d86ca!2s
          Ercan%20Havaliman%C4%B1!5e0!3m2!1str!2s!4v1686255849962!5m2!1str!2s" 
          width="100%" 
          height="300" 
          style={{border:0}} 
          allowFullScreen="" 
          title="Location"
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade"> 
            
          </iframe>
            </div>
          </Col> */}
          <Col lg="12">
            <div className="footer__bottom">
              <p className="section__description d-flex align-items-center justify-content-center gap-1 pt-4">
                <i className="ri-copyright-line"></i>Copyright {year}, Developed by
                New Month Rent A Car. All rights reserved.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
