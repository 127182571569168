import React, { useState, forwardRef } from "react";
import "../../styles/find-car-form.css";
import { Form, FormGroup, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import { addMonths } from 'date-fns';
import SelectCarModal from "./SelectCarModal";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import Tr from 'date-fns/locale/tr';
const FindCarForm = () => {
  const [startDate, setStartDate] = useState(null);
  const current = new Date();
  current.setDate(current.getDate() + 1);
  const [endDate, setEndDate] = useState(null);
  const [pickUpPlace, setPickUpPlace] = useState(null);
  const [dropOffPlace, setDropOffPlace] = useState(null);
  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);
  const [errorPickUpPlace, setErrorPickUpPlace] = useState(false);
  const [errorDropOffPlace, setErrorDropOffPlace] = useState(false);
  const [buttonValid, setButtonValid] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  };
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  };


  function handleChange(event) {
    const { name, value } = event.target;
    validate(name, value);
  }

  function validate(name, value) {
    if (name === "pick_up_place" && value === "") {
      setErrorPickUpPlace(false);
      setButtonValid(true)
    }
    if (name === "pick_up_place" && value !== "") {
      setErrorPickUpPlace(true)
      setPickUpPlace(value)
      setButtonValid(false)
    }
    if (name === "drop_off_place" && value === "") {
      setErrorDropOffPlace(false)
      setButtonValid(true)
    }
    if (name === "drop_off_place" && value !== "") {
      setErrorDropOffPlace(true)
      setDropOffPlace(value)
      setButtonValid(false)
    }
  }

  function handleChangeStartTime(date) {
    setStartDate(date)
    setEndDate(null)
  }

  const DatePickerCustomStart = forwardRef(({ value, onClick }, ref) => (
    <button className="datepicker-custom" onClick={onClick} ref={ref}>
      {value ? value : "Alış Tarih & Saat"}
    </button>
  ));

  const DatePickerCustomEnd = forwardRef(({ value, onClick }, ref) => (
    <button className="datepicker-custom" onClick={onClick} ref={ref}>
      {value ? value : "Teslim Tarih & Saat"}
    </button>
  ));
  
  return (
    <>
      <Form className="form" onSubmit={(e) => e.preventDefault()}>
        <div className=" d-flex align-items-center justify-content-between flex-wrap">
          <Label className="form__item-label">
            <span>
              <i className="ri-map-pin-line"></i>
            </span>
            <span>Alış</span>
          </Label>

          <FormGroup className="select__group ">
            <select onChange={handleChange} name="pick_up_place" >
              <option value="" hidden>Alış Yeri</option>
              <option value="Ercan Havalimanı">Ercan Havalimanı</option>
              <option value="Girne">Girne</option>
            </select>
            {errorPickUpPlace === false && <div className="required_field">Zorunlu Alan</div>}
          </FormGroup>

          <FormGroup className="form__group" id="date">
            <DatePicker
              selected={startDate}
              // onChange={(date) => setStartDate(date) }
              onChange={(date) => handleChangeStartTime(date)}
              name="pick_up_date"
              showIcon
              showTimeSelect
              locale={Tr}
              timeFormat="HH:mm"
              timeIntervals={60}
              dateFormat="dd/MM/yyyy HH:mm "
              minDate={new Date()}
              maxDate={addMonths(new Date(), 3)}
              customInput={<DatePickerCustomStart />}
            />
            {!startDate && <div className="required_field">Zorunlu Alan</div>}
          </FormGroup>

          <Label className="form__item-label">
            <span>
              <i className="ri-map-pin-line"></i>
            </span>
            <span>Teslim</span>
          </Label>

          <FormGroup className="select__group ">
            <select onChange={handleChange} name="drop_off_place" >
              <option value="" hidden>Teslim Yeri</option>
              <option value="Ercan Havalimanı">Ercan Havalimanı</option>
              <option value="Girne">Girne</option>
            </select>
            {errorDropOffPlace === false && <div className="required_field">Zorunlu Alan</div>}
          </FormGroup>

          <FormGroup className="form__group">
            <DatePicker

              selected={endDate}
              onChange={(date) => setEndDate(date)}
              showIcon
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={60}
              locale={Tr}
              dateFormat="dd/MM/yyyy HH:mm "
              minDate={startDate}
              maxDate={addMonths(new Date(), 4)}
              customInput={<DatePickerCustomEnd />}
            />
            {!endDate && <div className="required_field">Zorunlu Alan</div>}
          </FormGroup>

          <FormGroup className="form__group__btn d-flex justify-content-center align-item-center">
            {
              buttonValid === false && errorPickUpPlace === true && errorDropOffPlace === true && startDate && endDate
                ? <button className="btn find__car-btn" onClick={toggle}>Araçlarımız</button>
                : <button disabled={true} className="btn find__car-btn" onClick={toggle}>Araçlarımız</button>
            }

          </FormGroup>
        </div>
      </Form>

      <SelectCarModal
        modal={modal}
        toggle={toggle}
        closeAll={closeAll}
        toggleNested={toggleNested}
        toggleAll={toggleAll}
        nestedModal={nestedModal}
        pickUpPlace={pickUpPlace}
        dropOffPlace={dropOffPlace}
        startDate={startDate ? format(startDate, 'dd/MM/yyyy HH:mm') : 'as'}
        endDate={endDate ? format(endDate, 'dd/MM/yyyy HH:mm') : 'as'}
        startTime={startDate ? format(startDate, 'MM/dd/yyyy HH:mm') : 'as'}
        endTime={endDate ? format(endDate, 'MM/dd/yyyy HH:mm') : 'as'}
      />

    </>
  );
};

export default FindCarForm;