import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import aboutImg from "../assets/all-images/cars-img/bmw-offer.png";
import "../styles/not-found.css"
const NotFound = () => {
  useEffect(() => {
    window.scrollTo(1, 1);
  });
  return (
    <>
    <Helmet title="404 Not Found">
      <section >
        <Container>
          <Row>
            <Col lg="7" md="7" sm="12" xs="12">
              <div className="not-found-section">
                <p className="not-found-title" >404 NOT FOUND</p>
                <p className="not-found-desc">Sayfa Bulunamadı</p>
              </div>

            </Col>
            <Col lg="5" md="5" sm="12" xs="12">
              <div className="not-found-img">
                <img src={aboutImg} alt="" className="w-100 " />
              </div>

            </Col>

          </Row>

        </Container>
      </section>
      </Helmet>
    </>
    
  )
};

export default NotFound;
