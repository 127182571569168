import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import completedPng from "../../assets/all-images/completed.png"
import  "../../styles/reservation-completed.css"
const ReservationCompleted = (props) => {
  return (
    <>
        <Modal
        isOpen={props.completedModal}
        toggle={props.completedToggle}
        onClosed={props.closeAll ? props.toggle : undefined}
        backdrop={"static"}
        className="reservation__completed-modal"
      >
        <ModalHeader >Reservation Completed</ModalHeader>
        <ModalBody>
            <img src={completedPng} alt="" className="completed__img" />
        </ModalBody>
        <ModalFooter>
          <Button className="reservation__completed-ok" onClick={props.toggleAll}>
            Ok!
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ReservationCompleted