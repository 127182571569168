import React, { useRef } from "react";

import { Container, Row, Col } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import "../../styles/header.css";

const navLinks = [
  {
    path: "/",
    display: "Anasayfa",
  },
  {
    path: "/Hakkimizda",
    display: "Hakkımızda",
  },
  {
    path: "/Araclarimiz",
    display: "Araçlarımız",
  },
  {
    path: "/SSS",
    display: "Şartlar ve S.S.S",
  },
  // {
  //   path: "/blogs",
  //   display: "Blog",
  // },
  {
    path: "/Iletisim",
    display: "İletişim",
  },
  
];

const Header = () => {
  const menuRef = useRef(null);

  const toggleMenu = () => menuRef.current.classList.toggle("menu__active");

  return (
    <header className="header">
      {/* ============ header top ============ */}
      <div className="header__top">
        <Container>
          <Row>
            <Col lg="9" md="9" sm="9">
              <div className="header__top__left">
                <span>Yardıma mı ihtiyacınız var?</span>
                <span className="header__top__help">
                  <div>
                    <i className="ri-phone-fill"></i> İletişim: <a href="tel://+905488382222" rel="nofollow">+90-548-838-2222</a>
                    
                  </div>
                  <div>
                    <i className="ri-mail-line"></i> <a href="mailto:newmonthrentacar@gmail.com" >newmonthrentacar@gmail.com</a> 
                  </div>
                </span>
              </div>
            </Col>

            <Col lg="3" md="3" sm="3">
              <div className="header__top__right d-flex align-items-center justify-content-end gap-3">
                {/* <Link to="#" className=" d-flex align-items-center gap-1">
                  <i className="ri-facebook-line"></i>

                </Link> */}

                <Link to="tel://+905488562525" className=" d-flex align-items-center gap-1">
                  <i className="ri-phone-fill"></i>
                </Link>

                <Link to="https://wa.me/905488382222?text=Merhaba%20araç%20kiralamak%20istiyorum" className="wp__button d-flex align-items-center gap-1">
                  <i className="ri-whatsapp-line"></i>
                </Link>

              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* =============== header middle =========== */}
      <div className="header__middle">
        <Container>
          <Row>
            <Col lg="4" md="3" sm="4">
              <div className="logo">
                <h1>
                  <Link to="/" className=" d-flex align-items-center gap-2">
                    <i className="ri-car-fill"></i>
                    <span>
                      New Month <br /> Rent A Car
                    </span>
                  </Link>
                </h1>
              </div>
            </Col>

            <Col lg="3" md="3" sm="4">
              <div className="header__location d-flex align-items-center gap-2">
                <span>
                  <i className="ri-map-pin-line"></i>
                </span>
                <div className="header__location-content">
                  <h4>Kuzey Kıbrıs</h4>
                  <h6>Ercan Havalimanı & Girne</h6>
                </div>
              </div>
            </Col>

            <Col lg="3" md="3" sm="4">
              <div className="header__location d-flex align-items-center gap-2">
                <span>
                  <i className="ri-24-hours-line"></i>
                </span>
                <div className="header__location-content">
                  <h4>7 Gün</h4>
                  {/* <h6>09:00 - 20:00</h6> */}
                </div>
              </div>
            </Col>

            <Col
              lg="2"
              md="3"
              sm="0"
              className=" d-flex align-items-center justify-content-end "
            >
              <button className="header__btn btn ">
                <Link to="/Iletisim">
                  <i className="ri-phone-line"></i> İletişim
                </Link>
              </button>
            </Col>
          </Row>
        </Container>
      </div>

      {/* ========== main navigation =========== */}

      <div className="main__navbar ">
        <Container>
          
          <div className="mobile__menu-content d-flex">
            <Col lg="6" md="6" sm="6" xs="6">
            <span className="mobile__menu">
              <i className="ri-menu-line fs-3" onClick={toggleMenu}></i>
            </span>
            </Col>

            <Col lg="6" md="6" sm="6" xs="6">
            <div className="mobile__header__right d-flex align-items-center justify-content-end gap-3">
                
                <Link to="tel://+905488382222" className="call__button d-flex align-items-center gap-1">
                  <i className="ri-phone-fill fs-5"></i>
                </Link>

                <Link to="https://wa.me/905488382222?text=Merhaba%20araç%20kiralamak%20istiyorum" className="wp__button d-flex align-items-center gap-1">
                  <i className="ri-whatsapp-line fs-5"></i>
                </Link>

              </div>
            </Col>
            
          </div>
          
          <div className="navigation__wrapper d-flex align-items-center justify-content-center ">

            <div className="navigation " ref={menuRef} onClick={toggleMenu}>

              <div className="menu ">
                <div className="logo">
                  <h1>
                    <Link to="/" className=" d-flex align-items-center gap-2">
                      <i className="ri-car-fill"></i>
                      <span>
                      New Month <br /> Rent A Car
                      </span>
                    </Link>
                  </h1>
                </div>

                {navLinks.map((item, index) => (
                  <NavLink
                    to={item.path}
                    className={(navClass) =>
                      navClass.isActive ? "nav__active nav__item" : "nav__item"
                    }
                    key={index}
                  >
                    {item.display}
                  </NavLink>
                ))}
              </div>
            </div>


          </div>
        </Container>
      </div>
    </header>
  );
};

export default Header;
