import React, { useEffect } from "react";
import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import AboutSection from "../components/UI/AboutSection";
import { Container, Row, Col } from "reactstrap";

import driveImg from "../assets/all-images/drive.jpg";
import "../styles/about.css";

const About = () => {
  useEffect(() => {
    window.scrollTo(1, 1);
  });
  return (
    <Helmet title="Hakkımızda" url="Hakkimizda">
      <CommonSection title="Hakkımızda" />
      <AboutSection aboutClass="aboutPage" />
     
      <section className="about__page-section">
        <Container>
          <Row>
            <Col lg="6" md="12" sm="12">
              <div className="about__page-img">
                <img src={driveImg} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="12" sm="12">
              <div className="about__page-content">
                <h2 className="section__title">
                  Müşterilerimizi memnun etmekte kararlıyız...
                </h2>

                <p className="section__description">
                  Müşterilerimizin büyük bir kısmı Kuzey Kıbrıs Türk Cumhuriyeti Ercan Havalimanı'ndan
                  araç kiralamak istemektedir. Bu yüzden önceliğimiz Ercan Havalimanın'dan müşterilerimizin
                  memnuniyetini kazanmak..
                </p>

                <p className="section__description">
                  2010 yılından beri süre gelen tecrübe ve birikimimiz sayesinde mevcut müşterilerimiz ve bizi tavsiye ettikleri
                  yeni müşterilerimizin memnuniyeti motivasyon kaynağımız.. 
                </p>

                <p className="section__description">
                  Kuzey Kıbrıs Türk Cumhuriyeti; Ercan Havalimanı ve Girne'de müşterilerimizin isteklerine ve bütçelerine uygun 
                  ekonomik, orta sınıf ve lüks araba kiralama hizmeti veriyoruz..Değerleri müşterilerimize sadece Kuzey Kıbrıs'ın 
                  sakinliğinin, temiz havasının ve denizinin keyfini çıkarmak kalıyor.
                </p>


                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i className="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">Nasıl yardımcı olabiliriz?</h6>
                    <h4>+90-548-838-2222</h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

    </Helmet>
  );
};

export default About;
