import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import CarListing from "../pages/CarListing";
import CarDetails from "../pages/CarDetails";
// import Blog from "../pages/Blog";
// import BlogDetails from "../pages/BlogDetails";
import NotFound from "../pages/404";
import Contact from "../pages/Contact";
import Faq from "../pages/Faq"
import ReservationSend from "../pages/ReservationSend"
const Routers = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<Navigate to="/Anasayfa" />} />
      <Route path="/Anasayfa" element={<Home />} /> */}
       <Route path="/" element={<Home />} />
      <Route path="/Hakkimizda" element={<About />} />
      <Route path="/Araclarimiz" element={<CarListing />} />
      <Route path="/Araclarimiz/:slug" element={<CarDetails />} />
      {/* <Route path="/blogs" element={<Blog />} />
      <Route path="/blogs/:slug" element={<BlogDetails />} /> */}
      <Route path="/Iletisim" element={<Contact />} />
      <Route path="/SSS" element={<Faq />} />
      <Route path="/RezervasyonGonderildi" element={<ReservationSend />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Routers;
