// import all images from assets/images directory
// import img01 from "../all-images/cars-img/toyota-vitz-2020.jpg";
import img02 from "../all-images/cars-img/hyundai-i20-2014.jpg";
import img03 from "../all-images/cars-img/opel-corsa-2018.jpg";
import img04 from "../all-images/cars-img/suziki-swift-2020.jpg";
import img05 from "../all-images/cars-img/hyundai-elentra-2015.jpg";
import img06 from "../all-images/cars-img/bmw-520d-2015.jpg";
import img07 from "../all-images/cars-img/bmw-520d-2020.jpg";

import img08 from "../all-images/cars-img/opel-insignia-2020.jpg";
import img09 from "../all-images/cars-img/bmw-330e-2020.jpg";
import img10 from "../all-images/cars-img/toyota-vitz-2014.jpg";
import img11 from "../all-images/cars-img/hyundai-i10-2016.jpg";
import img12 from "../all-images/cars-img/suziki-swift-2016.jpg";
import img13 from "../all-images/cars-img/nissan-note-2020.jpg";

const carData = [
  /*
  {
    id: 1,
    brand: "Nissan",
    rating: 55,
    carName: "Nissan March",
    imgUrl: img08,
    model: "2016 Model",
    price: 900,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Otomatik/Klimalı",
    bluetooth: "Yok",
    fuel: "Benzin",
    person: "4 kişi",
    description:
      " Kıbrıs'ta araç kiralama hizmeti sunan firmamız sizi memnun etmenin gururunu yaşamaktadır. New Month Rent a Car firması olarak Kuzey Kıbrıs - Girne ve Ercan Havalimanı'nda sizlere araç kiralama hizmeti sunmaktayız. Özel fiyat almak için bizimle iletişime geçebilirsiniz. ",
  },
*/
 /* {
    id: 2,
    brand: "Honda",
    rating: 55,
    carName: "Honda Fit",
    imgUrl: img09,
    model: "2015 Model",
    price: 1000,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Otomatik/Klimalı",
    bluetooth: "Yok",
    fuel: "Benzin",
    person: "4 kişi",
    description:
      " Kıbrıs'ta araç kiralama hizmeti sunan firmamız sizi memnun etmenin gururunu yaşamaktadır. New Month Rent a Car firması olarak Kuzey Kıbrıs - Girne ve Ercan Havalimanı'nda sizlere araç kiralama hizmeti sunmaktayız. Özel fiyat almak için bizimle iletişime geçebilirsiniz. ",
  },*/
  /*
  {
    id: 1,
    brand: "Toyota",
    rating: 55,
    carName: "Toyota Vitz",
    imgUrl: img10,
    model: "2014 Model",
    price: 1200,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Otomatik/Klimalı",
    bluetooth: "Yok",
    fuel: "Benzin",
    person: "4 kişi",
    description:
      " Kıbrıs'ta araç kiralama hizmeti sunan firmamız sizi memnun etmenin gururunu yaşamaktadır. New Month Rent a Car firması olarak Kuzey Kıbrıs - Girne ve Ercan Havalimanı'nda sizlere araç kiralama hizmeti sunmaktayız. Özel fiyat almak için bizimle iletişime geçebilirsiniz. ",
  },
  */
  {
    id: 2,
    brand: "Hyundai",
    rating: 55,
    carName: "Hyundai i10",
    imgUrl: img11,
    model: "2016 Model",
    price: 1200,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Otomatik/Klimalı",
    bluetooth: "Yok",
    fuel: "Benzin",
    person: "4 kişi",
    description:
      " Kıbrıs'ta araç kiralama hizmeti sunan firmamız sizi memnun etmenin gururunu yaşamaktadır. New Month Rent a Car firması olarak Kuzey Kıbrıs - Girne ve Ercan Havalimanı'nda sizlere araç kiralama hizmeti sunmaktayız. Özel fiyat almak için bizimle iletişime geçebilirsiniz. ",
  },
 /*
  {
    id: 5,
    brand: "Suziki",
    rating: 55,
    carName: "Suziki Swift",
    imgUrl: img12,
    model: "2016 Model",
    price: 900,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Otomatik/Klimalı",
    bluetooth: "Yok",
    fuel: "Benzin",
    person: "4 kişi",
    description:
      " Kıbrıs'ta araç kiralama hizmeti sunan firmamız sizi memnun etmenin gururunu yaşamaktadır. New Month Rent a Car firması olarak Kuzey Kıbrıs - Girne ve Ercan Havalimanı'nda sizlere araç kiralama hizmeti sunmaktayız. Özel fiyat almak için bizimle iletişime geçebilirsiniz. ",
  },
  */
 /*
  {
    id: 3,
    brand: "Hyundai",
    rating: 55,
    carName: "Hyundai i20",
    imgUrl: img02,
    model: "2014 Model",
    price: 1200,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Otomatik/Klimalı",
    bluetooth: "Yok",
    fuel: "Benzin",
    person: "4 kişi",
    description:
      " Kıbrıs'ta araç kiralama hizmeti sunan firmamız sizi memnun etmenin gururunu yaşamaktadır. New Month Rent a Car firması olarak Kuzey Kıbrıs - Girne ve Ercan Havalimanı'nda sizlere araç kiralama hizmeti sunmaktayız. Özel fiyat almak için bizimle iletişime geçebilirsiniz. ",
  },
*/


  {
    id: 5,
    brand: "Suziki",
    rating: 100,
    carName: "Suziki Swift",
    imgUrl: img04,
    model: "2020 Model",
    price: 1400,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Otomatik/Klimalı",
    bluetooth: "Var",
    person: "4 kişi",
    fuel: "Benzin",
    description:
      " Kıbrıs'ta araç kiralama hizmeti sunan firmamız sizi memnun etmenin gururunu yaşamaktadır. New Month Rent a Car firması olarak Kuzey Kıbrıs - Girne ve Ercan Havalimanı'nda sizlere araç kiralama hizmeti sunmaktayız. Özel fiyat almak için bizimle iletişime geçebilirsiniz. ",
  },

  {
    id: 6,
    brand: "Nissan",
    rating: 100,
    carName: "Nissan Note",
    imgUrl: img13,
    model: "2020 Model",
    price: 1400,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Otomatik/Klimalı",
    bluetooth: "Var",
    person: "4 kişi",
    fuel: "Benzin",
    description:
      " Kıbrıs'ta araç kiralama hizmeti sunan firmamız sizi memnun etmenin gururunu yaşamaktadır. New Month Rent a Car firması olarak Kuzey Kıbrıs - Girne ve Ercan Havalimanı'nda sizlere araç kiralama hizmeti sunmaktayız. Özel fiyat almak için bizimle iletişime geçebilirsiniz. ",
  },

  {
    id: 7,
    brand: "Hyundai",
    rating: 35,
    carName: "Hyundai Elantra",
    imgUrl: img05,
    model: "2015 Model",
    price: 1500,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Otomatik/Klimalı",
    bluetooth: "Yok",
    person: "4 kişi",
    fuel: "Benzin",
    description:
      " Kıbrıs'ta araç kiralama hizmeti sunan firmamız sizi memnun etmenin gururunu yaşamaktadır. New Month Rent a Car firması olarak Kuzey Kıbrıs - Girne ve Ercan Havalimanı'nda sizlere araç kiralama hizmeti sunmaktayız. Özel fiyat almak için bizimle iletişime geçebilirsiniz. ",
  },

  {
    id: 8,
    brand: "Opel",
    rating: 119,
    carName: "Opel Insignia",
    imgUrl: img08,
    model: "2020 Model",
    price: 2000,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Otomatik/Klimalı",
    bluetooth: "Var",
    person: "4 kişi",
    fuel: "Dizel",
    description:
      " Kıbrıs'ta araç kiralama hizmeti sunan firmamız sizi memnun etmenin gururunu yaşamaktadır. New Month Rent a Car firması olarak Kuzey Kıbrıs - Girne ve Ercan Havalimanı'nda sizlere araç kiralama hizmeti sunmaktayız. Özel fiyat almak için bizimle iletişime geçebilirsiniz. ",
  },
  {
    id: 9,
    brand: "Bmw",
    rating: 119,
    carName: "Bmw 330e",
    imgUrl: img09,
    model: "2020 Model",
    price: 3000,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Otomatik/Klimalı",
    bluetooth: "Var",
    person: "4 kişi",
    fuel: "Benzin-Hybrid",
    description:
      " Kıbrıs'ta araç kiralama hizmeti sunan firmamız sizi memnun etmenin gururunu yaşamaktadır. New Month Rent a Car firması olarak Kuzey Kıbrıs - Girne ve Ercan Havalimanı'nda sizlere araç kiralama hizmeti sunmaktayız. Özel fiyat almak için bizimle iletişime geçebilirsiniz. ",
  },
  {
    id: 10,
    brand: "Bmw",
    rating: 119,
    carName: "Bmw 520d",
    imgUrl: img07,
    model: "2020 Model",
    price: 3500,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Otomatik/Klimalı",
    bluetooth: "Var",
    person: "4 kişi",
    fuel: "Dizel",
    description:
      " Kıbrıs'ta araç kiralama hizmeti sunan firmamız sizi memnun etmenin gururunu yaşamaktadır. New Month Rent a Car firması olarak Kuzey Kıbrıs - Girne ve Ercan Havalimanı'nda sizlere araç kiralama hizmeti sunmaktayız. Özel fiyat almak için bizimle iletişime geçebilirsiniz. ",
  },

];

export default carData;
