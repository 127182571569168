import React, {  useState } from "react";
// import { Link } from "react-router-dom";
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";

import "../styles/contact.css";
/*
const socialLinks = [
  {
    url: "#",
    icon: "ri-facebook-line",
  },
  {
    url: "#",
    icon: "ri-instagram-line",
  },
  {
    url: "#",
    icon: "ri-linkedin-line",
  },
  {
    url: "#",
    icon: "ri-twitter-line",
  },
];
*/
const Contact = () => {
  

  const [nameSurname, setNameSurname] = useState(null)
  const [nameSurnameValid, setNameSurnameValid] = useState(false)
  const [telNumber, setTelNumber] = useState(null)
  const [telNumberValid, setTelNumberValid] = useState(false)
  const [email, setEmail] = useState(null)
  const [emailValid, setEmailValid] = useState(false)
  const [message, setMessage] = useState(null)
  const [messageValid, setMessageValid] = useState(null)



  function handleChange(event) {
    const { name, value } = event.target;
    validate(name, value);
  }

  function validate(name, value) {
    if (name === "q4_namesurname" && value === "") {
      setNameSurnameValid(false)
      setNameSurname(null)
    }
    if (name === "q4_namesurname" && value !== "") {
      setNameSurnameValid(true)
      setNameSurname(value)
    }
    if (name === "q6_email" && value === "") {
      setEmailValid(false)
      setEmail(null)
    }
    if (name === "q6_email" && value !== "" ) {
       setEmailValid(true)
      setEmail(value)
    }
    if (name === "q5_tel" && value === "") {
      setTelNumberValid(false)
      setTelNumber(null)
    }
    if (name === "q5_tel" && value !== "") {
      setTelNumberValid(true)
      setTelNumber(value)
    }
    if (name === "q8_message" && value === "") {
      setMessageValid(false)
      setMessage(null)
    }
    if (name === "q8_message" && value !== "") {
      setMessageValid(true)
      setMessage(value)
    }
    
  }
  return (
    <Helmet title="İletişim">
      <CommonSection title="İletişim" />
      <section>
        <Container>
          <Row>
            <Col lg="7" md="7">
              <h6 className="fw-bold mb-4">Sorularınız ve Rezervasyon İçin Email Gönderebilirsiniz</h6>

              <Form action="https://submit.jotform.com/submit/231597281172055" method="post" name="form_231597281172055" id="231597281172055" autoComplete="off">
                <FormGroup className={nameSurname? "contact__form" : "contact__form error"}  >
                  <Input placeholder="İsim Soyisim" type="text" 
                    name="q4_namesurname"
                    onChange={handleChange}
                    value={nameSurname || ""} />
                </FormGroup>
                <FormGroup className={telNumber? "contact__form" : "contact__form error"}>
                  <Input placeholder="Telefon" type="number" 
                    name="q5_tel"
                    onChange={handleChange}
                    value={telNumber || ""} />
                </FormGroup>
                <FormGroup className={email ? "contact__form" : "contact__form error"}>
                  <Input placeholder="Email" type="text" 
                    name="q6_email"
                    onChange={handleChange}
                    value={email || ""} />
                </FormGroup>
                <FormGroup className={message? "contact__form" : "contact__form error"}>
                  <textarea
                    rows="5"
                    placeholder="Mesajınız"
                    className="textarea"
                    name="q8_message"
                    onChange={handleChange}
                    value={message || ""}
                  ></textarea>
                </FormGroup>

                <button className="btn contact__btn" type="submit"
                disabled={
                  (nameSurnameValid && telNumberValid && messageValid && emailValid) ?
                    false : true}
                >
                  Gönder
                </button>
              </Form>
            </Col>

            <Col lg="5" md="5">
              <div className="contact__info">
                <h6 className="fw-bold">İletişim Bilgileri</h6>
                <p className="section__description mb-0">
                  Kuzey Kıbrıs
                  Ercan Havalimanı & Girne
                </p>
                <div className=" d-flex align-items-center gap-2">
                  <h6 className="fs-6 mb-0">Telefon:</h6>
                  <p className="section__description mb-0">+90-548-838-2222</p>
                </div>
                <div className=" d-flex align-items-center gap-2">
                  <h6 className="fs-6 mb-0">Whatsapp:</h6>
                  <p className="section__description mb-0">+90-548-838-2222</p>
                </div>

                <div className=" d-flex align-items-center gap-2">
                  <h6 className="mb-0 fs-6">Email:</h6>
                  <p className="section__description mb-0">newmonthrentacar@gmail.com</p>
                </div>

                {/* <h6 className="fw-bold mt-4">Follow Us</h6>

                <div className=" d-flex align-items-center gap-4 mt-3">
                  {socialLinks.map((item, index) => (
                    <Link
                      to={item.url}
                      key={index}
                      className="social__link-icon"
                    >
                      <i className={item.icon}></i>
                    </Link>
                  ))}
                </div> */}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="google-map-code mt-4">
                <h5 className="footer__link-title">Girne Ofis</h5>
                <iframe
                  src="
              https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13020.177199637425!2d33.3282868!3d35.
              3297214!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14de6df47079a441%3A0x3c1066106f4669ce!2sNew%20
              Month%20Rent%20a%20Car!5e0!3m2!1str!2s!4v1686254255827!5m2!1str!2s"
                  width="100%"
                  height="350"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  title="Location"
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade">

                </iframe>
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div className="google-map-code mt-4">
                <h5 className="footer__link-title">Ercan Havalimanı</h5>
                <iframe
                  src="
                  https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2588.998843161453!2d33.50304279859238!
                  3d35.15826769762087!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14de3cf5804b085b%3A0x484a9aac114d86ca!2s
                  Ercan%20Havaliman%C4%B1!5e0!3m2!1str!2s!4v1686255849962!5m2!1str!2s"
                  width="100%"
                  height="350"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  title="Location"
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade">

                </iframe>
              </div>
            </Col>
          </Row>




        </Container>
      </section>
    </Helmet>
  );
};

export default Contact;
