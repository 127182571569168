import React, {  useEffect } from "react";

import HeroSlider from "../components/UI/HeroSlider";
import Helmet from "../components/Helmet/Helmet";

import { Container, Row, Col } from "reactstrap";
import FindCarForm from "../components/UI/FindCarForm";
import AboutSection from "../components/UI/AboutSection";
import ServicesList from "../components/UI/ServicesList";
import carData from "../assets/data/carData";
import CarItem from "../components/UI/CarItem";

// import BlogList from "../components/UI/BlogList";

const Home  = () => {
  useEffect(() => {
    window.scrollTo(1, 1);
  });
  
    return (
      <Helmet title="Anasayfa">
        {/* ============= hero section =========== */}
        <section className="p-0 hero__slider-section">
          <HeroSlider />
  
          <div className="hero__form ">
            <Container>
              <Row className="form__row">
                  <FindCarForm />
              </Row>
            </Container>
          </div>
        </section>
        {/* =========== about section ================ */}
        <AboutSection />
        {/* ========== services section ============ */}
        <section>
          <Container>
            <Row>
              <Col lg="12" className="mb-5 text-center">
                <h6 className="section__subtitle">Hizmetlerimiz</h6>
                <h2 className="section__title">Popüler Hizmetler</h2>
              </Col>
  
              <ServicesList />
            </Row>
          </Container>
        </section>
        {/* =========== car offer section ============= */}
        <section>
          <Container>
            <Row>
              <Col lg="12" className="text-center mb-5">
                <h6 className="section__subtitle">Şimdi Rezervasyon Yap</h6>
                <h2 className="section__title">Araçlarımız</h2>
              </Col>
  
              {carData.map((item) => (
                <CarItem item={item} key={item.id} />
              ))}
            </Row>
          </Container>
        </section>
  
        {/* =============== blog section =========== */}
        {/* <section>
          <Container>
            <Row>
              <Col lg="12" className="mb-5 text-center">
                <h6 className="section__subtitle">Explore our blogs</h6>
                <h2 className="section__title">Latest Blogs</h2>
              </Col>
  
              <BlogList />
            </Row>
          </Container>
        </section> */}
      </Helmet>
    );
  };
 
  

export default Home;
