import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label } from 'reactstrap';
import "../../styles/booking-information.css";
import moment from 'moment';

const BookingFormModal = (props) => {


    let start = new Date(props.startTime)
    let end = moment(props.endTime);
    const diff = end.diff(start);
    const diffDuration = moment.duration(diff);
    const days = diffDuration.days();
    const hours = diffDuration.hours();
    const [checked, setChecked] = useState(false);

    function getTotalDays(hours, days) {
        if (hours > 2)
            return days + 1;
        else
            return days;
    }

    function getTotalPrice(days, price) {
        if (days < 3)
            return 3 * price
        else
            return days*price
    }
    return (
        <>
            <Modal
                isOpen={props.modal}
                toggle={props.toggle}
                onClosed={props.closeAll ? props.toggle : undefined}
                backdrop={"static"}
                className="booking__information-modal"
            >
                <ModalHeader>Rezervasyon Talebi Gönder</ModalHeader>
                <ModalBody>

                    <Form action="https://submit.jotform.com/submit/231604689612055" method="post" name="form_231604689612055" id="231604689612055">
                        <FormGroup className="booking__information-form d-inline-block me-4 mb-4">
                            <input type="text" name="q3_alis" value={"Alış: " + props.pickUpPlace + " - " + props.startDate} readOnly />
                        </FormGroup>
                        <FormGroup className="booking__information-form d-inline-block me-4 mb-4">
                            <input type="text" name="q4_teslim" value={"Teslim: " + props.dropOffPlace + " - " + props.endDate} readOnly />
                        </FormGroup>
                        <FormGroup className="booking__information-form d-inline-block me-4 mb-4">
                            <input type="text" name="q5_aracbilgisi"
                                 value={getTotalDays(hours, days) + " Gün  / / " + props.carName +
                                     " - Toplam : ₺ " + getTotalPrice(getTotalDays(hours, days), props.price) || ""}
                                readOnly />
                        </FormGroup>
                        
                        
                        <FormGroup className="booking__information-form d-inline-block me-4 mb-4"   >
                            <input type="text" name="q6_isim" value={props.nameSurname} readOnly />
                        </FormGroup>

                        {props.email ?
                            <FormGroup className="booking__information-form d-inline-block me-4 mb-4"   >
                                <input type="email" name="q7_email" value={props.email} readOnly />
                            </FormGroup> :
                            console.log("")
                        }



                        <FormGroup className="booking__information-form d-inline-block me-4 mb-4"  >
                            <input type="number" name="q8_tel" value={props.telNumber} readOnly />
                        </FormGroup>

                        <FormGroup className="booking__information-form d-inline-block me-4 mb-4"  >
                            <input type="text" name="q9_kisisayisi" value={props.person} readOnly />
                        </FormGroup>

                        {props.message ?
                            <FormGroup className="booking__information-form d-inline-block me-4 mb-4"  >
                                <textarea
                                    rows={3}
                                    name="q10_mesaj"
                                    type="textarea"
                                    className="textarea"
                                    value={props.message}
                                    readOnly
                                ></textarea>
                            </FormGroup>
                            : console.log("")
                        }
                        <FormGroup className="booking__information-form d-inline-block me-4 mb-4">
                            <Label className="booking-bilgilendirme">*  Minimum 3 gün üzerinden işlem yapıldığından dolayı; 1 veya 2 günlük kiralamalarınızda 
              3 günlük fiyatlandırma yapılır.
              <br/> *  Aylık kiralamalarınızda özel fiyat için lütfen bizimle iletişime geçiniz. </Label>
                        </FormGroup>
                        {/* <FormGroup className='mt-2 ' onClick={() => setChecked((state) => !state)}>
                            <input type="checkbox" 
                                //  defaultChecked={checked}
                                 checked={checked}
                                //  onChange={() => setChecked((state) => !state)} 
                                />
                            {' '}
                            <Label check>
                                En az 24 yaşındaysanız kutucuğu işaretleyiniz.
                            </Label>
                        </FormGroup> */}
                        <Button
                            // className="booking__information-reservation" type="submit" disabled={checked ? false : true}
                            className="booking__information-reservation" type="submit" 
                        >
                            Gönder
                        </Button>
                    </Form>

                </ModalBody>
                <ModalFooter>
                    <Button className="booking__information-cansel"
                        onClick={props.toggle}
                    >
                        İptal Et
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default BookingFormModal