import React, { useState, forwardRef } from "react";
import "../../styles/booking-form.css";
import { Form, FormGroup } from "reactstrap";
import DatePicker from "react-datepicker";
import { addMonths } from 'date-fns';
import BookingFormModal from "./BookingFormModal";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import Tr from 'date-fns/locale/tr';
const BookingForm = (props) => {
  const [startDate, setStartDate] = useState(null);
  const current = new Date();
  current.setDate(current.getDate() + 1);
  const [endDate, setEndDate] = useState(null);
  const [pickUpPlace, setPickUpPlace] = useState(null);
  const [dropOffPlace, setDropOffPlace] = useState(null);
  
  const [nameSurname, setNameSurname] = useState(null)
  const [nameSurnameValid, setNameSurnameValid] = useState(false)
  const [telNumber, setTelNumber] = useState(null)
  const [telNumberValid, setTelNumberValid] = useState(false)
  const [email, setEmail] = useState(null)
  // const [emailValid, setEmailValid] = useState(false)
  const [person, setPerson] = useState(null)
  const [personValid, setPersonValid] = useState(false)
  const [message, setMessage] = useState(null)

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  function handleChange(event) {
    const { name, value } = event.target;
    validate(name, value);
  }

  function validate(name, value) {

    if (name === "pick_up_place" && value !== "") {

      setPickUpPlace(value)
    }

    if (name === "drop_off_place" && value !== "") {

      setDropOffPlace(value)
    }
    if (name === "name" && value === "") {
      setNameSurnameValid(false)
      setNameSurname(null)
    }
    if (name === "name" && value !== "") {
      setNameSurnameValid(true)
      setNameSurname(value)
    }
    if (name === "email" && value === "") {
      // setEmailValid(false)
      setEmail(null)
    }
    if (name === "email" && value !== "") {
      // setEmailValid(true)
      setEmail(value)
    }
    if (name === "telNumber" && value === "") {
      setTelNumberValid(false)
      setTelNumber(null)
    }
    if (name === "telNumber" && value !== "") {
      setTelNumberValid(true)
      setTelNumber(value)
    }
    if (name === "person" && value === "") {
      setPersonValid(false)
      setPerson(null)
    }
    if (name === "person" && value !== "") {
      setPersonValid(true)
      setPerson(value)
    }
    if (name === "message" && value === "") {
      setMessage(null)
    }
    if (name === "message" && value !== "") {
      setMessage(value)
    }
  }

  function handleChangeStartTime(date) {
    setStartDate(date)
    setEndDate(null)
  }


  const DatePickerCustomStart = forwardRef(({ value, onClick }, ref) => (
    <button className={startDate === null ? "datepicker-custom-bookingform error" : "datepicker-custom-bookingform"} onClick={onClick} ref={ref}>
      {value ? value : "Alış Tarih & Saat"}
    </button>
  ));

  const DatePickerCustomEnd = forwardRef(({ value, onClick }, ref) => (
    <button className={endDate === null ? "datepicker-custom-bookingform error" : "datepicker-custom-bookingform"} onClick={onClick} ref={ref}>
      {value ? value : "Dönüş Tarih & Saat"}
    </button>
  ));
  return (
    <>
      <Form action="/home" method="post" onSubmit={(e) => e.preventDefault()} autoComplete="off" >
        <FormGroup
          className={pickUpPlace ? "select__group booking__form d-inline-block mb-4" : "select__group booking__form d-inline-block error mb-4"}>
          <select onChange={handleChange} name="pick_up_place" >
          <option value="" hidden>Alış Yeri</option>
              <option value="Ercan Havalimanı">Ercan Havalimanı</option>
              <option value="Girne">Girne</option>
          </select>
        </FormGroup>
        <FormGroup className="booking__form d-inline-block  mb-4" >
          <DatePicker
            selected={startDate}
            onChange={(date) => handleChangeStartTime(date)}
            name="pick_up_date"
            showIcon
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={60}
            locale={Tr}
            dateFormat="dd/MM/yyyy HH:mm "
            minDate={new Date()}
            maxDate={addMonths(new Date(), 3)}
            placeholderText="Pick-Up Date"
            customInput={<DatePickerCustomStart />}
          />
        </FormGroup>
        <FormGroup
          className={dropOffPlace ? "select__group booking__form d-inline-block mb-4" : "select__group booking__form d-inline-block error mb-4"}>
          <select onChange={handleChange} name="drop_off_place" >
          <option value="" hidden>Teslim Yeri</option>
              <option value="Ercan Havalimanı">Ercan Havalimanı</option>
              <option value="Girne">Girne</option>
          </select>
        </FormGroup>

        <FormGroup className="booking__form d-inline-block  mb-4">
          <DatePicker

            selected={endDate}
            onChange={(date) => setEndDate(date)}
            showIcon
            showTimeSelect
            timeFormat="HH:mm"
            locale={Tr}
            timeIntervals={60}
            dateFormat="dd/MM/yyyy HH:mm "
            minDate={startDate}
            maxDate={addMonths(new Date(), 4)}
            placeholderText="Drop-Off Date"
            customInput={<DatePickerCustomEnd />}
          />
        </FormGroup>
        <FormGroup className={nameSurnameValid ? "booking__form d-inline-block  mb-4" : "booking__form d-inline-block error  mb-4"} >
          <input type="text" placeholder="Ad Soyad" name="name"
            onChange={handleChange}
            value={nameSurname || ""} />
        </FormGroup>

        <FormGroup className="booking__form d-inline-block  mb-4">
          <input type="text" placeholder="Email"
            name="email"
            onChange={handleChange}
            value={email || ""} />
        </FormGroup>
        <FormGroup className={telNumberValid ? "booking__form d-inline-block  mb-4" : "booking__form d-inline-block error mb-4"}>
          <input type="number" placeholder="Telefon" name="telNumber"
            onChange={handleChange}
            value={telNumber || ""} />
        </FormGroup>

        <FormGroup className={personValid ? "select__group booking__form d-inline-block  mb-4" : "select__group booking__form d-inline-block error mb-4"}>
          <select name="person" id="" onChange={handleChange} value={person || ""}>
            <option value="" hidden >Kişi Sayısı</option>
            <option value="1 Kişi">1 Kişi</option>
            <option value="2 Kişi">2 Kişi</option>
            <option value="3 Kişi">3 Kişi</option>
            <option value="4 Kişi">4 Kişi</option>
            <option value="5+ Kişi">5+ Kişi</option>
          </select>

        </FormGroup>

        <FormGroup>
          <textarea
            rows={5}
            type="textarea"
            className="textarea"
            placeholder="Mesajınız"
            name="message"
            onChange={handleChange}
            value={message || ""}
          ></textarea>
        </FormGroup>

        <FormGroup className="form__group__btn d-flex justify-content-center align-item-center">
          <button className="btn find__car-btn" type="submit" onClick={toggle}
            disabled={
              (nameSurnameValid && telNumberValid && personValid && startDate && endDate && pickUpPlace && dropOffPlace) ?
                false : true}>
            Devam Ediniz
          </button>
        </FormGroup>
      </Form>
      <BookingFormModal
        modal={modal}
        toggle={toggle}
        pickUpPlace={pickUpPlace}
        dropOffPlace={dropOffPlace}
        startDate={startDate ? format(startDate, 'dd/MM/yyyy HH:mm') : 'as'}
        endDate={endDate ? format(endDate, 'dd/MM/yyyy HH:mm') : 'as'}
        startTime={startDate ? format(startDate, 'MM/dd/yyyy HH:mm') : 'as'}
        endTime={endDate ? format(endDate, 'MM/dd/yyyy HH:mm') : 'as'}
        nameSurname = {nameSurname}
        telNumber = {telNumber}
        email ={email}
        person ={person}
        carName = {props.carName}
        price = {props.price}
        message= {message}
      />
    </>
  );
};

export default BookingForm;
