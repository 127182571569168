import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import aboutImg from "../assets/all-images/cars-img/bmw-offer.png";
import "../styles/reservation-send.css"
const ReservationSend = () => {
    useEffect(() => {
        window.scrollTo(1, 1);
      });
      return (
        <>
        <Helmet title="Rezervasyon İletildi">
          <section >
            <Container>
              <Row>
                <Col lg="7" md="7" sm="12" xs="12">
                  <div className="reservation-send-section">
                    <p className="reservation-send-title" >Rezervasyon Talebiniz İletilmiştir</p>
                    <p className="reservation-send-desc">Araç müsaitliğine bakıyoruz. Dönüş yapacağız.</p>
                  </div>
    
                </Col>
                <Col lg="5" md="5" sm="12" xs="12">
                  <div className="reservation-send-img">
                    <img src={aboutImg} alt="" className="w-100 " />
                  </div>
    
                </Col>
    
              </Row>
    
            </Container>
          </section>
          </Helmet>
        </>
        
      )
}

export default ReservationSend